// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-calls-js": () => import("./../../../src/pages/calls.js" /* webpackChunkName: "component---src-pages-calls-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact_us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-cubacel-recharge-js": () => import("./../../../src/pages/cubacel_recharge.js" /* webpackChunkName: "component---src-pages-cubacel-recharge-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nauta-recharge-js": () => import("./../../../src/pages/nauta_recharge.js" /* webpackChunkName: "component---src-pages-nauta-recharge-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-privacy-notice-js": () => import("./../../../src/pages/privacy_notice.js" /* webpackChunkName: "component---src-pages-privacy-notice-js" */),
  "component---src-pages-return-and-cancelation-js": () => import("./../../../src/pages/return_and_cancelation.js" /* webpackChunkName: "component---src-pages-return-and-cancelation-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms_and_conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */)
}


/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import './src/theme/styles.scss';

/* import React from 'react';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

export const onRouteUpdate = () => {
  document.body.classList.remove('no-scroll');
};

export const wrapRootElement = ({ element }) => (
  <MuiPickersUtilsProvider utils={DateFnsUtils}>
    {element}
  </MuiPickersUtilsProvider>
); */
